<!--
 * @Description: 单选题
 * @Author: liqin
 * @Date: 2022-02-17 17:32:34
-->
<template>
  <div>
    <div class="mt20">
      <div>
        <span style="color: #999" v-if="!paper.isShowQuestionType">单选题</span>
        {{"\xa0\xa0\xa0"}}
        <span v-if="!paper.isShowQuestionScore">总分：{{ val.score }} 分</span>
        {{"\xa0\xa0\xa0"}}
        <span class="red" v-if="isScore && paper.isShowAchievement">得分：{{val.userGetScore}}</span>
      </div>
      <div class="dian mt10">{{ val.bankName }} {{ "\xa0" }} </div>
    </div>
    <div class="mt5" v-for="k in val.options" :key="k.optionId">
      <el-radio-group v-model="radio" :disabled="isView" @change="savePracticeUserOptionApi(k)">
        <el-radio :label="k.optionSort" :class="val.userOptionIsok==1?'green':'error'">
          <span>{{ k.optionSort }}、 {{ k.optionName }}</span>
        </el-radio>
      </el-radio-group>
    </div>
    <div class="mt10" v-if="isAnswer && paper.isShowAnswer">
      正确答案：{{ val.bankAnswer }}
    </div>
    <div class="mt10" v-if="isAnswer && paper.isShowAnswer">
       <el-button type="primary" size="mini" @click="dialogVisible = true">答案解析</el-button>
    </div>
    <!-- 答案解析弹框 -->
    <el-dialog
      title="答案解析"
      :visible.sync="dialogVisible"
      width="40%">
      <span v-if="val.bankAnswerParse" v-html="val.bankAnswerParse"></span>
      <span v-else style="text-align: center;">无答案解析</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    val: [Object],
    isView: {
      type: Boolean,
      default: () => false
    },
    isAnswer: { // 答案显示
      type: Boolean,
      default: () => false
    },
    isScore: { // 得分显示
      type: Boolean,
      default: () => false
    },
    paper: { // 
      type: Object,
      default: () => {
        return {}
      }
    },
    userPapersId: [String],
    topicOutlineId: {
      type: String,
      default: () =>  {
        return ''
      }
    }
  },
  data() {
    return {
      radio: '',
      dialogVisible: false
    };
  },
  methods: {
    savePracticeUserOptionApi(value) {
      let practiceUserOptionStr = []

      practiceUserOptionStr.push({
        optionId: value.optionId
      })
      //提交问题
      let params = {
        userPagersId: this.userPapersId,
        papersId: this.$route.query.papersId,
        bankId: value.bankId,
        practiceUserOptionStr: JSON.stringify(practiceUserOptionStr),
      }
      this.$api.savePracticeUserOptionApi(params).then(res => {
        let tempZero = {
          bankId:value.bankId+this.topicOutlineId,
          subList:this.radio
        }
        this.$emit('addBankTypeZero', tempZero)
      })
    }
  },
  watch: {
    val: {
      deep: true,
      immediate: true,
      handler(val) {
        val.options.forEach(item => {
          item.isCheck == 1 ? this.radio = item.optionSort : ''
        })
      }
    }
  }

};
</script>
<style lang="scss" scoped>
.red {
  color: red;
}
.green ::v-deep.el-radio__input.is-disabled.is-checked .el-radio__inner{
  background-color: #18cca7;
  border-color: #0b8d73;
}
.error ::v-deep.el-radio__input.is-disabled.is-checked .el-radio__inner{
  background-color: #e47a7a;
  border-color: #a62b2b;
}
</style>
